import request from '@/utils/request'

export function queryProposal(parameter) {
  return request({
    url: '/advise/page',
    method: 'post',
    data: parameter
  })
}

export function putProposalReply(parameter) {
  return request({
    url: '/advise/reply',
    method: 'post',
    data: parameter
  })
}

export function deleteProposal(parameter) {
  return request({
    url: '/advise/delete/' + parameter.id,
    method: 'DELETE',
  })
}

export function getProposal(parameter) {
  return request({
    url: '/advise/' + parameter.id,
    method: 'get',
  })
}