<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card title="主要信息" :bordered='false'>
      <a-descriptions>
        <a-descriptions-item label='提交人'>{{ profile.masterName }}</a-descriptions-item>
        <a-descriptions-item label='总结主题'>{{ profile.title }}</a-descriptions-item>
        <a-descriptions-item label='提交时间'>{{ profile.createTime }}</a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card title="总结内容" style="margin-top: 20px;" :bordered='false'>
      <a-descriptions>
        <a-descriptions-item label='做得好的点'>{{ profile.good }}</a-descriptions-item>
        <a-descriptions-item :span="2">
          <div style="display: flex;">
            <div @click="previewVisible = true; previewImage = item.url;" class="image_list" v-for="(item, index) in goodImagesList" :key="index">
              <template v-if="isVideoUrl(item.url)">
                <video class="image_detail" :src="item.url"></video>
                <img class="play_image" :src="playImg" >
              </template>
              <img v-else class="image_detail" :src="item.url" >
            </div>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label='不足的点'>{{ profile.good }}</a-descriptions-item>
        <a-descriptions-item :span="2">
          <div style="display: flex;">
            <div @click="previewVisible = true; previewImage = item.url;" class="image_list" v-for="(item, index) in badImagesList" :key="index">
              <template v-if="isVideoUrl(item.url)">
                <video class="image_detail" :src="item.url"></video>
                <img class="play_image" :src="playImg" >
              </template>
              <img v-else class="image_detail" :src="item.url" >
            </div>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label='下次立即使用的点'>{{ profile.good }}</a-descriptions-item>
        <a-descriptions-item :span="2">
          <div style="display: flex;">
            <div @click="previewVisible = true; previewImage = item.url;" class="image_list" v-for="(item, index) in usedImagesList" :key="index">
              <template v-if="isVideoUrl(item.url)">
                <video class="image_detail" :src="item.url"></video>
                <img class="play_image" :src="playImg" >
              </template>
              <img v-else class="image_detail" :src="item.url" >
            </div>
          </div>
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card title='回复' style="margin-top: 20px;" :bordered='false'>
      <template v-if="profile.replyContent">
        <a-descriptions>
          <a-descriptions-item label='回复内容'>{{ profile.replyContent }}</a-descriptions-item>
          <a-descriptions-item label='回复人'>{{ profile.operatorName }}</a-descriptions-item>
          <a-descriptions-item label='回复时间'>{{ profile.replyTime }}</a-descriptions-item>
        </a-descriptions>
      </template>
      <template v-else>
        <a-textarea v-model="content" :rows="4" />
        <a-descriptions>
          <a-descriptions-item :span="3">
          </a-descriptions-item>
          <a-descriptions-item :span="3">
            <a-button type='primary' @click='handleSubmit'>
              提交
            </a-button>
          </a-descriptions-item>
        </a-descriptions>
      </template>
    </a-card>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <video style="width: 100%" v-if="isVideoUrl(previewImage)" :src="previewImage" controls autoplay></video>
      <img v-else alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { putProposalReply, getProposal } from '@/api/proposal'
import { isVideoUrl } from '@/utils/util'

export default {
  name: 'ProposalEdit',

  data() {
    return {
      playImg: require('@/assets/play.jpg'),
      loading: false,
      profile: {},
      goodImagesList: [],
      badImagesList: [],
      usedImagesList: [],
      content: "",
      previewVisible: false,
      previewImage: "",
    }
  },
  components: { STable, Ellipsis },
  created() {
    this.handleInitial()
  },
  activated() {
    this.handleInitial();
    // debugger;
  },
  methods: {
    isVideoUrl,
    handleSubmit() {
      putProposalReply({
        id: this.$route.query.id,
        replyContent: this.content,
      }).then(result => {
        this.$message.success('操作成功')
        this.$router.go(-1);
      })
    },
    handleInitial() {
      getProposal(this.$route.query).then(result => {
        this.profile = Object.assign({}, this.profile, result)
        const goodImagesList = [];
        const badImagesList = [];
        const usedImagesList = [];
        if (this.profile.list) {
          for (const item of this.profile.list) {
            if (item.imgType == 1) {
              goodImagesList.push(item)
            } else if (item.imgType == 2) {
              badImagesList.push(item)
            } else if (item.imgType == 3) {
              usedImagesList.push(item)
            }
          }
        }
        this.goodImagesList = goodImagesList;
        this.badImagesList = badImagesList;
        this.usedImagesList = usedImagesList;
      })
    }
  }
}
</script>
<style lang="less" scoped>
.image_list {
  width: 150px;
  height: 150px;
  margin: 0 20px 20px 0;
  object-fit: cover;
  position: relative;
  .image_detail {
    height: 100%;
    width: 100%;
  }
  .play_image {
    position: absolute;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
  }
}
</style>
